import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import AuthService from '@/features/auth/services/auth.service';
import { useApolloClient } from '@apollo/client';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

interface MenuItem {
  name: string;
  icon: JSX.Element;
  onClick: () => void;
  legacyPagePaths: string[];
}

export function useAccountMenuItems() {
  const { isAdmin, isRouteVisible, sitemaps, refresh } = useCurrentUser();
  const navigate = useNavigate();
  const client = useApolloClient();

  // Define the items array using useMemo
  const items: MenuItem[] = useMemo(() => [
    {
      name: 'Account Settings',
      icon: <SettingsIcon />,
      onClick: () => navigate('/accountSettings'),
      legacyPagePaths: ['/userMyAccount.aspx']
    },
    {
      name: 'Favourites',
      icon: <FavoriteIcon />,
      onClick: () => navigate('/favourites'),
      legacyPagePaths: ['/orderV2.aspx', '/order.aspx']
    },
    {
      name: 'Log Out',
      icon: <LogoutIcon />,
      onClick: () => {
        client.clearStore();
        AuthService.logout();
        localStorage.removeItem('selectedOrderTypeId');
        navigate('/signin');
      },
      legacyPagePaths: ['/orderV2.aspx', '/order.aspx']
    },
  ], [navigate, client]);

  useEffect(() => {
    refresh();
  }, [sitemaps, isRouteVisible, items, refresh]);

  return items.filter((i) => i.legacyPagePaths.some((p) => isRouteVisible(p)));
}