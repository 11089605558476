/*
  This component renders a small form containing two radio buttons
  which allow you to select either of the following Product Statuses:
    1. "At Client" - this means the product is in CLIENT's LOCATION and needs to be COLLECTED by us.
    2. "In Warehouse" - this means the product is in OUR WAREHOUSE and needs to be DELIVERED to client's location.
*/
import {
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ProductStatusEnum } from '../../../common/enums/productStatusEnum';
import { getCurrentTargetId, setCurrentProductStatusId, setCurrentTargetId } from '../hooks/archiveTarget';

interface Props {
  onOptionChange?: () => void;
}

export function ArchiveOrderOptions(props: Props) {
  const [currentArchiveTarget, setCurrentArchiveTarget] = useState<ProductStatusEnum>(() => {
    const storedValue = localStorage.getItem('current_archive_target');
    return storedValue !== null ? Number(storedValue) : ProductStatusEnum.AtClient;
  });

  useEffect(() => {
    localStorage.setItem('current_archive_target', currentArchiveTarget.toString() ?? ProductStatusEnum.AtClient.toString());
  }, [currentArchiveTarget])
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Archive Order
        </Typography>
        <RadioGroup
          value={currentArchiveTarget.toString()}
          onChange={(e) => {
            setCurrentArchiveTarget(Number(e.currentTarget.value));
            setCurrentProductStatusId(Number(e.currentTarget.value));
            props?.onOptionChange?.();
          }}
        >
          <FormControlLabel
            value={ProductStatusEnum.AtClient}
            control={<Radio />}
            label="Book collection of box(es)"
          />
          <FormControlLabel
            value={ProductStatusEnum.InWarehouse}
            control={<Radio />}
            label="Have box(es) delivered"
          />
        </RadioGroup>
      </CardContent>
    </Card>
  );
}
