import { ProductStatusEnum } from '../../../common/enums/productStatusEnum';

export const getCurrentTargetId = () => Number(localStorage.getItem('current_archive_target'));

export const setCurrentTargetId = (newTargetId: ProductStatusEnum) => {
  localStorage.setItem('current_archive_target', newTargetId.toString());
}

export const getCurrentProductStatusId = () => Number(localStorage.getItem('current_product_status'));

export const setCurrentProductStatusId = (newProductId: ProductStatusEnum) => {
  localStorage.setItem('current_product_status', newProductId.toString());
}