import { useCurrentUser } from '@/common/auth/hooks/use-current-user';
import { TsuField, TsuLoading } from '@/common/components';
import { useUserActionCategories } from '@/features/administration/role/components/RoleActionPermissionsList';
import { AdminOnly } from '@/features/auth/components/AdminOnly';
import { getDestructionModeOn, setDestructionModeOnForCartBuffer } from '@/features/cart/components/CartInformationBuffer';
import { useFreightSettingsQuery } from '@/graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BulletList } from 'react-content-loader';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCheckoutStep, useCheckoutStore } from '../checkout-store';
import { ShippingMethodForm } from '../interfaces';
import { useStepCardContext } from './StepCard';

// Define the VALIDATION schema of our "Shipping Method Form".
const schema: yup.SchemaOf<ShippingMethodForm> = yup.object({
  freightSettingId: yup.number().required("Please select a shipping method"),
  isUrgentOrder: yup.bool().optional().default(false),
  chargeProcessingFee: yup.bool().optional().default(false),
  processingFeeNotes: yup.string().optional().default(''),
});

export function ShippingMethod() {
  let destructionModeStatus = getDestructionModeOn();
  const { currentUser } = useCurrentUser();
  const {
    userActionCategories,
  } = useUserActionCategories(currentUser?.role?.roleId);
  const [canSeeUrgentOrderOption, setCanSeeUrgentOrderOption] = useState(false);
  // These values are provided by our parent Checkout Step Card (React Context).
  const { stepIndex, submitTitle, nextStep } = useStepCardContext();
  // These values are provided by the Checkout Store (Zustand).
  const { currentStep } = useCheckoutStep();
  // Setter function for saving Shipping Method in our Checkout Store (Zustand).
  const submitShippingMethod = useCheckoutStore((s) => s.submitShippingMethod);

  // activate the submit button only if shipping method is selected
  const [disable, setDisable] = useState(true);

  const handleSelect = () => {
    setDisable(false);
  };

  // Define our form state using react-hook-form & yup.
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ShippingMethodForm>({
    defaultValues: {
    },
    resolver: yupResolver(schema),
  });

  // Freight Settings choices
  const {
    data,
    loading: isLoadingFreightSettings,
    error,
  } = useFreightSettingsQuery({
    fetchPolicy: 'network-only',
  });

  // Derived state
  const freightSettings = data?.freightSettings;
  const freightSettingChoices = freightSettings?.map((fs) => ({
    text: fs.name,
    value: fs.freightSettingId,
  }));
  const isReadOnly = currentStep !== stepIndex;

  const onSubmit = handleSubmit(async (data) => {
    setDestructionModeOnForCartBuffer(destructionModeStatus);
    // console.log('Shipping Method data:', data);
    submitShippingMethod(data);
    nextStep();
  });

  useEffect(() => {
    userActionCategories.map((cat, i) => {
      if(cat.name === "Order Delivery Details Screen" && cat.userActions.some((action) => action.actionId === 116 && action.isActive)) {
        setCanSeeUrgentOrderOption(true);
      }
    });
  }, [userActionCategories, currentUser]);

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2}>
        <TsuLoading
          loading={isLoadingFreightSettings}
          error={error}
          loader={<BulletList width="100%" height={100} />}
        >
          <TextField
            {...register('freightSettingId')}
            select
            variant="outlined"
            size="small"
            disabled={isReadOnly}
            value={watch('freightSettingId') ?? ''}
            label={!isReadOnly ? 'Select a Shipping Method' : ''}
          >
            {freightSettingChoices?.map((c, i) => (
              <MenuItem key={i} value={c.value} onClick={handleSelect}
              >
                {c.text === 'Standard' ? (c.text + ' - Will be despatched within 48 hours, no urgent fee applied') : 
                c.text === "Express" ? (c.text + ' - Use Express Service, will incur a higher freight charge') 
                : (c.text)}
              </MenuItem>
            ))}
          </TextField>
          {errors.freightSettingId ? (
            <FormHelperText error>{errors.freightSettingId.message}</FormHelperText>
          ) : null}
        </TsuLoading>
        <Stack spacing={2}>
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Stack spacing={!isReadOnly ? 2 : 0}>
                {canSeeUrgentOrderOption ? 
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Controller
                      name="isUrgentOrder"
                      control={control}
                      render={({ field }) => (
                        <Checkbox {...field} checked={field.value} disabled={isReadOnly} />
                      )}
                    />
                    <Typography variant="h6">Urgent Order</Typography>
                    <Tooltip
                      title="This means that your order will be marked as URGENT, and will incur a prioritization fee."
                      placement="top"
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Stack>
                : null}
                {!isReadOnly && <Divider />}
                <AdminOnly>
                  {
                    <>
                      {!isReadOnly && <Typography variant="h4">Processing Fee</Typography>}
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Controller
                          name="chargeProcessingFee"
                          control={control}
                          render={({ field }) => (
                            <Checkbox {...field} checked={field.value} disabled={isReadOnly} />
                          )}
                        />
                        <Typography variant="h6">Charge Processing Fee</Typography>
                        <Tooltip
                          title="Please indicate the reason for waiving the processing fee. Processing fee when a BSA staff member places an order on behalf of a client. Ie phone or email orders."
                          placement="top"
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                        <TsuField
                          {...register('processingFeeNotes')}
                          multiline
                          label="Note"
                          onlyValue={isReadOnly}
                          rows={4}
                        />
                        {isReadOnly && watch('processingFeeNotes') === '' && (
                          <Typography ml={2}>No notes.</Typography>
                        )}
                      </Stack>
                    </>
                  }
                </AdminOnly>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
        <Box hidden={isReadOnly}>
          <Grid container>
            <Grid xs={12} item>
              <Stack direction="row" spacing={2}>
                <Button type="submit" variant="contained" sx={{ alignSelf: 'start', width: '50%' }} disabled={disable}>
                  {submitTitle}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </form>
  );
}
